import React from "react";
import { graphql } from "gatsby";
import TeamPage from "../team";

const LocalizedTeamPage = (props) => <TeamPage {...props} />;

export default LocalizedTeamPage;

// If you modify this query, copy it to the src/pages/team.js file too. These need to be in sync.
export const query = graphql`
  query {
    allMembers {
      edges {
        node {
          data {
            attributes {
              full_name
              note
              patron_status
            }
            relationships {
              currently_entitled_tiers {
                data {
                  alternative_id
                  type
                }
              }
              pledge_history {
                data {
                  alternative_id
                  type
                }
              }
            }
          }
          included {
            attributes {
              tier_id
              tier_title
              title
            }
            alternative_id
          }
        }
      }
    }
  }
`;
